import React, { useState } from "react";
import { Button } from "@mui/material";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { IoMdArrowRoundBack } from "react-icons/io";
import { FcApproval } from "react-icons/fc";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { tldSelectArray } from "../const";
import * as _ from "lodash";

import "./style.css";
import { resetHolder } from "../utils";

export const SearchResult = ({ setPageState, domains, setDomains }) => {
    const [alignment, setAlignment] = useState('crypto');

    const handleAlignment = (event, newAlignment) => {
        setAlignment(newAlignment);
    };

    const DomainCard = ({ data }) => {
        return (
            <a href={data.link} target="_blank" className="no-decoration">
                <div className="domain-card">
                    <FcApproval size={40} />
                    <div className="text-wrapper">
                        <h>
                            {data.name}
                        </h>
                        <p>
                            ${Number(data.price) / 100}
                        </p>
                    </div>
                    <AiOutlineShoppingCart size={40} />
                </div>
            </a>
        )
    }
    return (
        <div className="result-wrapper">
            <Button
                style={{ width: 100 }}
                variant="text"
                onClick={() => {
                    resetHolder();
                    setPageState(0);
                    setDomains(null);
                }}
            >
                <IoMdArrowRoundBack />
                Back
            </Button>
            <h>
                Available Domains
            </h>
            <p>
                Any domain register through UD999CLUB, <span>10% of the registration fee</span> will be refunded after orders confirm.
            </p>
            <ToggleButtonGroup
                value={alignment}
                exclusive
                onChange={handleAlignment}
                aria-label="text alignment"
                color="primary"
                style={{ "margin": "auto" }}
            >
                {tldSelectArray.map((tld, index) => (
                    <ToggleButton value={tld} style={{ fontWeight: "bold" }}>
                        {'.' + tld}
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>
            <div className="result-details-box">
                <div className="result-details-wrapper">
                    {domains === null &&
                        <h2>
                            Loading...
                        </h2>
                    }
                    {(domains && !_.get(domains, [alignment])) &&
                        <h2>
                            No domain Available
                        </h2>
                    }
                    {_.get(domains, [alignment]) &&
                        _.get(domains, [alignment]).map((data, index) => (
                            <DomainCard data={data} />
                        ))
                    }
                </div>
            </div>
        </div>
    )
}