import './App.css';
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Search } from "./pages/search";
import { Home } from "./pages/home";
import { AppHome } from "./pages/app";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      select: "",
      domains: {},
      loading: false,
    };
  }

  render() {
    return (
      <div className="App" >
        <Router>
          <Routes>
            <Route path="/" element={<Home data={this} />} />
            <Route path="/search" element={<Search data={this} />} />
            <Route path="/app" element={<AppHome data={this} />} />
          </Routes>
        </Router>
      </div>
    );
  }
}

export default App;
