import React from "react";

import { Header } from "../components/header";
import { Mainbox } from "../components/mainbox";

export const Search = ({ data }) => {
    return (
        <div>
            <Header />
            <Mainbox />
        </div>
    )
}