import React, { useEffect, useState, useContext } from "react";
import { PageContext } from "../../context";
import { FaDiscord, FaTwitter } from "react-icons/fa";
import { AiOutlineFileSearch } from "react-icons/ai";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { tldSelectArray } from "../../const";
// import { dataHolder } from "../../utils";
import "./RightMain.css";
import CustomPaginationActionsTable from "./DataTable";
import mobileMenuImg from "../images/mobile-menu.png";
import { WebLogo } from "../leftBar/leftBar";
import logoImage from "../images/logo.png";

export const RightMain = ({ data }) => {
  const [tld, setTld] = useState("crypto");
  const [rows, setRows] = useState([]);
  const [page, setPage] = useContext(PageContext);

  const handleChange = (event, newValue) => {
    setTld(newValue);
    setPage(0);
  };

  useEffect(() => {
    if (tld in data.state.domains) {
      setRows(data.state.domains[tld]);
    } else {
      setRows([]);
    }
  }, [data.state.domains, tld]);

  return (
    <div className="right-main-wrapper">
      <div className="right-header">
        <h>Unstoppable Domain batch registration</h>
        <button className="mobile-header">
          <WebLogo image={logoImage} name={"UD999CLUB"} />
        </button>
        <div className="mobile-menu">
          <button
            onClick={() => {
              var x = document.getElementById("bar-wrapper");
              if (!x.style.display || x.style.display === "none") {
                x.style.display = "flex";
              } else {
                x.style.display = "none";
              }
            }}
          >
            <AiOutlineFileSearch size={30} />
          </button>
        </div>
        <div className="social-meida-wrapper">
          <a
            href="https://discord.gg/yWTtCxd7B4"
            target="blank"
            className="no-decoration"
          >
            <FaDiscord size={20} color={"#616161"} />
          </a>
          <a
            href="https://twitter.com/UD999club"
            target="blank"
            className="no-decoration"
          >
            <FaTwitter size={20} color={"#616161"} />
          </a>
        </div>
      </div>
      <>
        <TabContext value={tld}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              {tldSelectArray.map((tld, index) => {
                let domain = data.state.domains[tld] || [];
                const labelName = `${tld}(${domain === undefined ? "0" : domain.length
                  })`;
                return (
                  <Tab
                    className="table-tab"
                    label={labelName}
                    value={tld}
                    style={{ fontWeight: "bold" }}
                  />
                );
              })}
            </TabList>
          </Box>
        </TabContext>
      </>
      <>
        {data.state.loading ? (
          <h>Loading data...</h>
        ) : (
          <CustomPaginationActionsTable rows={rows} />
        )}
      </>
    </div>
  );
};
