import React from "react";
import { QuickSelection } from "./quickSelection/QuickSelection";
import { AdvancedSearch } from "./AdvancedSearch/AdvancedSearch";
import logoImage from "../images/logo.png";

import "./leftBar.css";

export const WebLogo = ({ image, name }) => {
  return (
    <a href="./" className="left-logo-wrap">
      <img src={image} alt="Logo" />
      <h>{name}</h>
    </a>
  );
};

export const LeftBar = ({ data }) => {
  return (
    <div id="bar-wrapper" className="bar-wrapper">
      <div className="bar-header">
        <WebLogo image={logoImage} name={"UD999CLUB"} />
        <span
          onClick={() => {
            var x = document.getElementById("bar-wrapper");
            if (!x.style.display || x.style.display === "none") {
              x.style.display = "flex";
            } else {
              x.style.display = "none";
            }
          }}
        >
          x
        </span>
      </div>
      <QuickSelection data={data} />
      <AdvancedSearch data={data} />
    </div>
  );
};
