import React from "react";
import "./style.css";
import logoImage from "./images/logo.png";
import { WebLogo } from "./leftBar/leftBar";
import { REFER_LINK } from "../const";

export const Header = () => {
    return (
        <div className="header-wrapper">
            <div className="left-wrapper">
                <WebLogo image={logoImage} name={"UD999CLUB"} />
                <a href={REFER_LINK} target="_blank" className="ud-wrapper">
                    <img src={"https://docs.unstoppabledomains.com/images/logo.png"} alt="Logo" />
                </a>
            </div>
        </div>
    )
}