import React, { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { lengthArray, charsArray, patternArray, aArray } from "../../../menu";
import { generateTxt, checkBatchDomains } from "../../../utils";
import "./AdvancedSearch.css";

export const AdvancedSearch = ({ data }) => {
  const setDomains = (domains) => {
    data.setState({
      domains,
    });
  };
  const setLoading = (loading) => {
    data.setState({
      loading,
    });
  };
  const [length, setLength] = useState("3");
  const [chars, setChars] = useState("digital");
  const [pattern, setPattern] = useState("A**");
  const [a, setA] = useState("0");
  const [b, setB] = useState("0");
  const [c, setC] = useState("0");
  const [value, setValue] = useState("");
  const [domainArray, setDomainArray] = useState({});

  const handleLengthChange = (event) => {
    setLength(event.target.value);
  };
  const handleCharsChange = (event) => {
    setChars(event.target.value);
  };
  const handlePatternChange = (event) => {
    setPattern(event.target.value);
  };
  const handleAChange = (event) => {
    setA(event.target.value);
  };
  const handleBChange = (event) => {
    setB(event.target.value);
  };
  const handleCChange = (event) => {
    setC(event.target.value);
  };
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const handleGenerate = () => {
    const serachTxt = generateTxt(pattern, a, b, c, aArray[chars]);
    setValue(serachTxt.join(", "));
  };

  const handleSearch = () => {
    var consultData = value.split(",");
    //TODO: fliter space

    consultData = consultData.map((element) => {
      return element.trim();
    });
    consultData = consultData.filter((word) => word.length > 2);
    console.log("Consulting", consultData);
    checkBatchDomains(consultData, setDomains, setLoading);
  };

  // useEffect(() => {
  //     // const tmpHolder = JSON.parse(JSON.stringify(domainArray));
  //     // var temObject = {}
  //     // for (const [key, value] of Object.entries(tmpHolder)) {
  //     //     if (value.tld in temObject) {
  //     //         if (temObject[value.tld]) {
  //     //             temObject[value.tld].push(value);
  //     //         }
  //     //         else {
  //     //             temObject[value.tld] = [value];
  //     //         }
  //     //     }
  //     //     else {
  //     //         temObject[value.tld] = [value];
  //     //     }
  //     // }
  //     console.log("domainArray", domainArray);
  //     // setDomains(temObject);
  // }, [domainArray]);

  const SelectTemplate = ({
    selectHandler,
    selectArray,
    state,
    isDisabled = false,
  }) => {
    return (
      <FormControl
        sx={{ m: 1, width: "100%", margin: 0, backgroundColor: "white" }}
        size="small"
        disabled={isDisabled}
      >
        <Select
          value={state}
          onChange={selectHandler}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
        >
          {selectArray.map((slt, index) => (
            <MenuItem value={slt}>{slt}</MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };
  return (
    <div className="qselection-wrapper">
      <h>Advacned search</h>
      {domainArray.length}
      <div className="search-input">
        <div className="length-chars">
          <div className="half-input box-input">
            <h>Length:</h>
            <SelectTemplate
              selectHandler={handleLengthChange}
              selectArray={lengthArray}
              state={length}
            />
          </div>
          <div className="half-input box-input">
            <h>Chars:</h>
            <SelectTemplate
              selectHandler={handleCharsChange}
              selectArray={charsArray}
              state={chars}
            />
          </div>
        </div>
        <div className="full-input box-input">
          <h>Pattern:</h>
          <SelectTemplate
            selectHandler={handlePatternChange}
            selectArray={patternArray[length]}
            state={pattern}
          />
        </div>
        <div className="length-chars">
          <div className="one-three-input box-input">
            <h>A:</h>
            <SelectTemplate
              selectHandler={handleAChange}
              selectArray={aArray[chars]}
              state={a}
            />
          </div>
          <div className="one-three-input box-input">
            <h>B:</h>
            <SelectTemplate
              selectHandler={handleBChange}
              selectArray={aArray[chars]}
              state={b}
              isDisabled={!pattern.includes("B")}
            />
          </div>
          <div className="one-three-input box-input">
            <h>C:</h>
            <SelectTemplate
              selectHandler={handleCChange}
              selectArray={aArray[chars]}
              state={c}
              isDisabled={!pattern.includes("C")}
            />
          </div>
        </div>
      </div>
      <button
        className="search-button"
        onClick={() => {
          handleGenerate();
        }}
      >
        Generate data
      </button>
      <div className="input-block">
        <TextField
          fullWidth
          id="outlined-multiline-static"
          multiline
          rows={4}
          value={value}
          placeholder="Enter domins, seperated by comma"
          onChange={handleChange}
          style={{ backgroundColor: "white" }}
        />
      </div>
      {value && (
        <button
          className="search-button"
          onClick={() => {
            handleSearch();
          }}
        >
          Search
        </button>
      )}
    </div>
  );
};
