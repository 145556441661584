import React, { useState } from "react";
import { SearchWindow } from "./searchWindow";
import { SelectTab } from "./selectTab";
import { SearchResult } from "./result";
import "./mainBox.css";

export const Mainbox = () => {
    const [value, setValue] = useState('');
    const [pageState, setPageState] = useState(0);
    const [domains, setDomains] = useState(null);
    return (
        <div className="fullwidth">
            {pageState === 0 &&
                <div className="mainBox-wrapper">
                    <SelectTab value={value} setValue={setValue} />
                    <SearchWindow value={value} setValue={setValue} setPageState={setPageState} domains={domains} setDomains={setDomains} />
                </div>
            }
            {pageState === 1 &&
                <SearchResult value={value} setPageState={setPageState} domains={domains} setDomains={setDomains} />
            }
        </div>
    )
}