import React from "react";
import MenuItems from "./MenuItems";
import "./dropdown.css";

const Dropdown = ({
  submenus,
  dropdown,
  depthLevel,
  setSelect,
  setLoading,
}) => {
  depthLevel = depthLevel + 1;
  const shiftClass = "shift" + submenus.length;
  const dropdownClass = depthLevel > 1 ? "dropdown-submenu " + shiftClass : "";
  return (
    <ul className={`dropdown ${dropdownClass} ${dropdown ? "show" : ""}`}>
      {submenus.map((submenu, index) => (
        <MenuItems
          items={submenu}
          key={index}
          depthLevel={depthLevel}
          setSelect={setSelect}
          setLoading={setLoading}
        />
      ))}
    </ul>
  );
};

export default Dropdown;
