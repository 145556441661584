import axios from "axios";
import * as _ from "lodash";
import {
  BUY_LINK,
  PARTNER_SEARCH_API,
  PARTNER_SEARCH_TOKEN,
  PUBLIC_SEARCH_API,
} from "./const";
import { quickSelectionMap, digital } from "./map";

var dataHolder = {};
var interval = null;
var shouldFallBack = false; // false: use public API, true: use partner API
function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

var startTime, endTime;

function start() {
  startTime = new Date();
}

function end() {
  endTime = new Date();
  var timeDiff = endTime - startTime; //in ms
  // strip the ms
  timeDiff /= 1000;

  // get seconds
  var seconds = Math.round(timeDiff);
  console.log("spent: ", seconds + " seconds");
}

function handleAxiosError(error) {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.error("No response from server for request: ", error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.error("Error", error.message);
  }
  console.log(error.config);
}

const partnerCheckDomain = async (domain) => {
  const res = await axios
    .get(PARTNER_SEARCH_API + domain, {
      headers: {
        Authorization: "Bearer " + PARTNER_SEARCH_TOKEN,
      },
    })
    .catch(function (error) {
      handleAxiosError(error);
    });

  const records = _.get(res, ["data", "domains"]);

  for (const [key, value] of Object.entries(records)) {
    const registered = _.get(value, ["availability", "registered"]);
    if (!registered) {
      const name = _.get(value, ["domain", "name"]);
      const tld = name.split(".")[1];
      const isProtected = _.get(value, ["availability", "protected"]);
      const price = _.get(value, ["availability", "price"]) * 100; // multiple 100 so it's consistent with legacy path
      // exclude tld klever
      if (tld === "klever" || isProtected || price == null) {
        continue;
      }
      console.log("DATA FROM DB", tld, name, price);
      dataHolder[name] = { tld, name, price, link: BUY_LINK + name };
      console.log("dataHolder", dataHolder);
    }
  }
};

const legacyCheckDomain = async (domain) => {
  axios
    .get(PUBLIC_SEARCH_API + domain)
    .then(async (res) => {
      const records = _.get(res, ["data", "exact"]);
      for (const [key, value] of Object.entries(records)) {
        // console.log(`${key}: ${value}`);
        const status = _.get(value, ["status"]);
        if (status === "available") {
          const tld = _.get(value, ["domain", "extension"]);
          const name = _.get(value, ["domain", "name"]);
          const price = _.get(value, ["price"]);
          console.log("DATA FROM DB", tld, name, price);
          dataHolder[name] = { tld, name, price, link: BUY_LINK + name };
          console.log("dataHolder", dataHolder);
        }
      }
    })
    .catch(function (error) {
      handleAxiosError(error);
      shouldFallBack = true;
    });
};

export const resetHolder = async () => {
  if (interval != null) {
    clearInterval(interval);
  }
  dataHolder = {};
};

export const checkBatchDomains = async (domains, setArr, setLoading) => {
  resetHolder();
  setLoading(true);
  regularCheck(setLoading, setArr);
  var count = 0;
  for (let element of domains) {
    count++;
    if (count > 100) {
      await sleep(getRandomInt(50));
    } else {
      await sleep(getRandomInt(10));
    }
    start();
    if (!shouldFallBack) {
      legacyCheckDomain(element);
    } else {
      await partnerCheckDomain(element);
    }
    end();
  }
};

const regularCheck = async (setLoading, setArr) => {
  await sleep(2000);
  var tmpHolder = JSON.parse(JSON.stringify(dataHolder));
  var temObject = {};
  for (const [key, value] of Object.entries(tmpHolder)) {
    if (value.tld in temObject) {
      if (temObject[value.tld]) {
        temObject[value.tld].push(value);
      } else {
        temObject[value.tld] = [value];
      }
    } else {
      temObject[value.tld] = [value];
    }
  }
  setLoading(false);
  console.log("temObject", temObject);
  setArr(temObject);

  if (interval != null) {
    clearInterval(interval);
  }
  let lastTime = "";
  interval = setInterval(function () {
    const tmpHolder = JSON.parse(JSON.stringify(dataHolder));
    var temObject = {};
    for (const [key, value] of Object.entries(tmpHolder)) {
      if (value.tld in temObject) {
        if (temObject[value.tld]) {
          temObject[value.tld].push(value);
        } else {
          temObject[value.tld] = [value];
        }
      } else {
        temObject[value.tld] = [value];
      }
    }
    console.log("temObject", temObject);
    setArr(temObject);
    if (lastTime === JSON.stringify(temObject)) {
      clearInterval(interval);
    }
    lastTime = JSON.stringify(temObject);
  }, 3000);
};

export const generateTxt = (pattern, a, b, c, sarray) => {
  var txtRes = [];
  if (!a) {
    a = sarray[0];
  }
  if (!b) {
    b = sarray[0];
  }
  if (!c) {
    c = sarray[0];
  }
  generateTxtRecursive(pattern, a, b, c, sarray, "", txtRes);
  console.log("txtRes", txtRes);
  return txtRes;
};
const generateTxtRecursive = (pattern, a, b, c, sarray, prefix, res) => {
  if (pattern.length === 0) {
    res.push(prefix);
    // console.log("res", res);
  } else {
    const currentLetter = pattern.substring(0, 1);
    const leftLetters = pattern.substring(1);
    if (currentLetter === "*") {
      for (let i of sarray) {
        generateTxtRecursive(leftLetters, a, b, c, sarray, prefix + i, res);
      }
    } else if (currentLetter === "A") {
      generateTxtRecursive(leftLetters, a, b, c, sarray, prefix + a, res);
    } else if (currentLetter === "B") {
      generateTxtRecursive(leftLetters, a, b, c, sarray, prefix + b, res);
    } else if (currentLetter === "C") {
      generateTxtRecursive(leftLetters, a, b, c, sarray, prefix + b, res);
    }
  }
};

export const quickSearch = (pattern, setArr, setLoading) => {
  var domains = [];
  if (pattern in quickSelectionMap) {
    domains = quickSelectionMap[pattern];
  } else {
    domains = generateQuickDomains(pattern);
  }
  console.log("Final domians", domains);
  checkBatchDomains(domains, setArr, setLoading);
};

const generateQuickDomains = (pattern) => {
  var domains = [];
  generateOneCharDomains(pattern, "", {}, domains);
  return domains;
};
const generateOneCharDomains = (subPattern, generated, position, domains) => {
  if (!subPattern) {
    domains.push(generated);
  } else {
    const currentChar = subPattern.substring(0, 1);
    const leftChar = subPattern.substring(1);
    if (currentChar === "0") {
      generated += "0";
      generateOneCharDomains(leftChar, generated, position, domains);
    } else if (currentChar in position) {
      generated += generated[position[currentChar]];
      generateOneCharDomains(leftChar, generated, position, domains);
    } else {
      const newPosition = {
        ...position,
        [currentChar]: generated.length,
      };
      digital.map((symbol) => {
        generateOneCharDomains(
          leftChar,
          generated + symbol,
          newPosition,
          domains
        );
      });
    }
  }
};

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
