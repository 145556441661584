import React, { useState } from "react";
import { menuItems } from '../menu';
import MenuItems from './dropdown/MenuItems';
import "./accordion.css";


export const AccordionSelect = ({ data }) => {
    const setDomains = (domains) => {
        data.setState({
            domains
        })
    }

    const setLoading = (loading) => {
        data.setState({
            loading
        })
    }

    return (
        <ul className="menus">
            {menuItems.map((menu, index) => {
                const depthLevel = 0;
                return (
                    <MenuItems
                        items={menu}
                        key={index}
                        depthLevel={depthLevel}
                        setSelect={setDomains}
                        setLoading={setLoading}
                    />
                );
            })}
        </ul>
    )
}