import React, { useState, useEffect, useRef } from "react";
import { AiOutlineDoubleRight } from "react-icons/ai";
import { quickSearch } from "../../utils";
import { useNavigate } from "react-router-dom";

import Dropdown from "./Dropdown";
import "./dropdown.css";

const MenuItems = ({ items, depthLevel, setSelect, setLoading }) => {
  const navigate = useNavigate();

  const [dropdown, setDropdown] = useState(false);

  let ref = useRef();
  const topLevelcss = depthLevel === 0 ? "top-level-button " : "no-border";
  useEffect(() => {
    const handler = (event) => {
      if (dropdown && ref.current && !ref.current.contains(event.target)) {
        setDropdown(false);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [dropdown]);

  const onMouseEnter = () => {
    setDropdown(true);
  };

  const onMouseLeave = () => {
    setDropdown(false);
  };

  const closeDropdown = () => {
    dropdown && setDropdown(false);
  };

  const handleDropDownClick = () => {
    setDropdown((prev) => !prev);
  }

  const handleSelect = (items) => {
    navigate("/app");
    var finalPattern = "";
    if (items.pattern) {
      finalPattern = items.pattern;
    } else {
      finalPattern = items.title;
    }
    // setSelect(finalPattern);
    quickSearch(finalPattern, setSelect, setLoading);
  };
  return (
    <li
      className="menu-items"
      ref={ref}
    // onMouseEnter={onMouseEnter}
    // onMouseLeave={onMouseLeave}
    // onClick={handleDropDownClick}
    >
      {items.submenu ? (
        <>
          <button
            type="button"
            aria-haspopup="menu"
            aria-expanded={dropdown ? "true" : "false"}
            className={topLevelcss}
            onClick={() => {
              setDropdown((prev) => !prev);
            }}
          >
            {items.title}{" "}
            {depthLevel > 0 ? (
              <AiOutlineDoubleRight />
            ) : (
              <span className="arrow" />
            )}
          </button>
          <Dropdown
            depthLevel={depthLevel}
            submenus={items.submenu}
            dropdown={dropdown}
            setSelect={setSelect}
            setLoading={setLoading}
          />
        </>
      ) : (
        <a
          onClick={() => {
            handleSelect(items);
          }}
        >
          {items.title}
        </a>
      )}
    </li>
  );
};

export default MenuItems;
