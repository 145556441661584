import React, { useState } from "react";
import { FaDiscord, FaTwitter } from "react-icons/fa";

import { Header } from "../components/header";
// import { Mainbox } from "../components/mainbox";
import { AccordionSelect } from "../components/accordion";
import "./home.css"

export const Home = ({ data }) => {
    const [search, setSerach] = useState(false);

    return (
        <div className="home-wrapper">
            <Header />
            <h1>
                Unstoppable Domains Batch Registration
            </h1>
            <h2>
                <span style={{ color: "#4650EF" }}>Get <span style={{ textDecoration: "line-through" }}>10%</span> <span style={{ color: "red" }}>30% this month</span> registration fee back</span> when registering through UD999Club
            </h2>
            {
                !search && <>
                    <div className="quick-selection-wrapper">
                        <h>
                            Quick selection
                        </h>
                        <AccordionSelect data={data} />
                    </div>
                    {/* <a
                        className="switch-search"
                        onClick={() => { setSerach(!search) }}
                    >
                        Advanced search
                    </a> */}
                </>
            }

            {
                search && <>
                    <a
                        className="switch-search"
                        onClick={() => { setSerach(!search) }}
                    >
                        Quick selection
                    </a>
                    <div className="quick-selection-wrapper">
                        <h>
                            Advanced search
                        </h>
                    </div>
                </>
            }
            <div className="right-wrapper">
                <a href="https://discord.gg/yWTtCxd7B4" target="blank" className="no-decoration">
                    <FaDiscord size={25} />
                </a>
                <a href="https://twitter.com/UD999club" target="blank" className="no-decoration" >
                    <FaTwitter size={25} />
                </a>
            </div>


        </div>
    )
}