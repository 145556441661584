import React, { useState } from "react";
import { LeftBar } from "../components/leftBar/leftBar";
import { RightMain } from "../components/rightMain/RightMain";
import { PageContext } from "../context";
import "./app.css";

export const AppHome = ({ data }) => {
    const [page, setPage] = useState(0);
    return (
        <PageContext.Provider value={[page, setPage]}>
            <div className="apphome-wrapper">
                <LeftBar data={data} />
                <RightMain data={data} />
            </div>
        </PageContext.Provider>
    )
}