import React, { useState } from "react";
import "./style.css";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Button } from "@mui/material";
import { tldSelectArray } from "../const";
const lengthArray = ['3', '4', '5'];
const charsArray = ['digital', 'letter'];
const patternArray = {
    3: ['A**', '*A*', '**A', 'AB*', 'A*B', '*AB'],
    4: ['AB**', 'A*B*', 'A**B', '*AB*', '*A*B', '**AB'],
    5: ['ABC**', 'AB*C*', 'AB**C', 'A*BC*', 'A*B*C', 'A**BC', '*ABC*', '*AB*C', '*A*BC', '**ABC']
}
const aArray = {
    digital: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
    letter: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]
}
const generateTxt = (pattern, a, b, c, sarray) => {
    var txtRes = [];
    if (!a) {
        a = sarray[0];
    }
    if (!b) {
        b = sarray[0];
    }
    if (!c) {
        c = sarray[0];
    }
    generateTxtRecursive(pattern, a, b, c, sarray, "", txtRes);
    console.log("txtRes", txtRes);
    return txtRes;
}
const generateTxtRecursive = (pattern, a, b, c, sarray, prefix, res) => {
    if (pattern.length === 0) {
        res.push(prefix);
        // console.log("res", res);
    }
    else {
        const currentLetter = pattern.substring(0, 1);
        const leftLetters = pattern.substring(1);
        if (currentLetter === "*") {
            for (let i of sarray) {
                generateTxtRecursive(leftLetters, a, b, c, sarray, prefix + i, res);
            }
        }
        else if (currentLetter === "A") {
            (generateTxtRecursive(leftLetters, a, b, c, sarray, prefix + a, res))
        }
        else if (currentLetter === "B") {
            (generateTxtRecursive(leftLetters, a, b, c, sarray, prefix + b, res))
        }
        else if (currentLetter === "C") {
            (generateTxtRecursive(leftLetters, a, b, c, sarray, prefix + b, res))
        }
    }
}

export const SelectTab = ({ value, setValue }) => {
    const [tld, setTld] = useState("crypto");
    const [length, setLength] = useState('3');
    const [chars, setChars] = useState('digital');
    const [pattern, setPattern] = useState('A**');
    const [a, setA] = useState(null);
    const [b, setB] = useState(null);
    const [c, setC] = useState(null);

    const handleTldChange = (event) => {
        setTld(event.target.value);
    };
    const handleLengthChange = (event) => {
        setLength(event.target.value);
    };
    const handleCharsChange = (event) => {
        setChars(event.target.value);
    };
    const handlePatternChange = (event) => {
        setPattern(event.target.value);
    };
    const handleAChange = (event) => {
        setA(event.target.value);
    };
    const handleBChange = (event) => {
        setB(event.target.value);
    };
    const handleCChange = (event) => {
        setC(event.target.value);
    };
    const handleGenerate = () => {
        if (!a) {
            alert("Please select correct A value!")
            return
        }
        const serachTxt = generateTxt(pattern, a, b, c, aArray[chars]);
        setValue(serachTxt.join('\n'))
    }

    const SelectTemplate = ({ selectHandler, selectArray, state }) => {
        return (
            <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
                <Select
                    value={state}
                    onChange={selectHandler}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                >
                    {selectArray.map((slt, index) => (
                        <MenuItem value={slt}>{slt}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        )
    }
    return (
        <div className="select-tab">
            <h>CATEGORY</h>
            <div className="tab-wrapper">
                <p>TLD:</p>
                <SelectTemplate selectHandler={handleTldChange} selectArray={tldSelectArray} state={tld} />
            </div>
            <div className="tab-wrapper">
                <p>Length:</p>
                <SelectTemplate selectHandler={handleLengthChange} selectArray={lengthArray} state={length} />
            </div>
            <div className="tab-wrapper">
                <p>Chars:</p>
                <SelectTemplate selectHandler={handleCharsChange} selectArray={charsArray} state={chars} />
            </div>
            <div className="tab-wrapper">
                <p>Pattern:</p>
                <SelectTemplate selectHandler={handlePatternChange} selectArray={patternArray[length]} state={pattern} />
            </div>
            <div className="tab-wrapper">
                <p>A:</p>
                <SelectTemplate selectHandler={handleAChange} selectArray={aArray[chars]} state={a} />
            </div>
            <div className="tab-wrapper">
                <p>B:</p>
                <SelectTemplate selectHandler={handleBChange} selectArray={aArray[chars]} state={b} />
            </div>

            {length === '5' &&
                <div className="tab-wrapper">
                    <p>C:</p>
                    <SelectTemplate selectHandler={handleCChange} selectArray={aArray[chars]} state={c} />
                </div>
            }
            <Button
                style={{ width: 100, 'align-self': 'center' }}
                variant="contained"
                onClick={() => {
                    handleGenerate();
                }}
            >
                Generate
            </Button>
        </div>
    )
}