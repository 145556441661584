export const menuItems = [
    {
        title: '👑 999 Club',
        submenu: [
            { title: 'ALL3', },
            { title: 'AAA', },
            { title: 'AAB', },
            { title: 'ABB', },
            { title: 'ABA', },
            { title: '00X', },
            { title: 'X00', },
        ]
    },
    {
        title: '💎 10K Club',
        submenu: [
            { title: 'ALL4', },
            { title: 'X000', },
            { title: 'AAAA', },
            { title: 'AAAB', },
            {
                title: 'AA**',
                submenu: [
                    { title: 'AABA', },
                    { title: 'AABB', },
                    { title: 'AABC', },
                ]
            },
            {
                title: 'A***',
                submenu: [
                    { title: 'ABAA', },
                    { title: 'ABAB', },
                    { title: 'ABBB', },
                    { title: 'ABBA', },
                    { title: 'ABCC', },
                    { title: 'ABBC', },
                    { title: 'ABCB', },
                ]
            },
        ]
    },
    {
        title: '🤩 100K Club',
        submenu: [
            { title: '0000X', },
            { title: 'X0000', },
            { title: 'AAAAA', },
            { title: 'AAAAB', },
            {
                title: 'AAA**',
                submenu: [
                    { title: 'AAABA', },
                    { title: 'AAABB', },
                    { title: 'AAABC', },
                ]
            },
            {
                title: 'AA***',
                submenu: [
                    { title: 'AABAA', },
                    { title: 'AABAB', },
                    { title: 'AABBB', },
                    { title: 'AABBA', },
                    { title: 'AABCC', },
                    { title: 'AABBC', },
                    { title: 'AABCB', },
                ]
            },
            {
                title: 'A****',
                submenu: [
                    { title: 'ABBBB', },
                    { title: 'ABBBA', },
                    { title: 'ABABA', },
                    { title: 'ABBAA', },
                    { title: 'ABBAB', },
                    { title: 'ABAAB', },
                    { title: 'ABABB', },
                    { title: 'ABACA', },
                    { title: 'ABBBC', },
                    { title: 'ABCBA', },
                ]
            },
        ]
    },
    {
        title: '🌟 100K Club',
        submenu: [
            { title: '00000X', },
            { title: 'X00000', },
            { title: 'AAAAAA', },
            { title: 'AAAAAB', },
            {
                title: 'AAAA**',
                submenu: [
                    { title: 'AAAABA', },
                    { title: 'AAAABB', },
                    { title: 'AAAABC', },
                ]
            },
            {
                title: 'AAA***',
                submenu: [
                    { title: 'AAABAA', },
                    { title: 'AAABAB', },
                    { title: 'AAABBB', },
                    { title: 'AAABBA', },
                    { title: 'AAABCC', },
                    { title: 'AAABBC', },
                    { title: 'AAABCB', },
                ]
            },
            {
                title: 'AA****',
                submenu: [
                    { title: 'AABBBB', },
                    { title: 'AABBBA', },
                    { title: 'AABAAA', },
                    { title: 'AABBAA', },
                    { title: 'AABBAB', },
                    { title: 'AABAAB', },
                    { title: 'AABABB', },
                    { title: 'AABABA', },
                ]
            },
            {
                title: 'A*****',
                submenu: [
                    { title: 'ABAABA', },
                    { title: 'ABABAB', },
                    { title: 'ABBBBA', },
                    { title: 'ABBBBB', },
                    { title: 'ABBABB', },
                    { title: 'ABBCBB', },
                    { title: 'ABBAAB', },
                    { title: 'ABBACC', },
                    { title: 'ABCABC', },
                    { title: 'ABCCCC', },
                ]
            },
        ]
    },
];


export const lengthArray = ['3', '4', '5'];
export const charsArray = ['digital', 'letter'];
export const patternArray = {
    3: ['A**', '*A*', '**A', 'AB*', 'A*B', '*AB'],
    4: ['AB**', 'A*B*', 'A**B', '*AB*', '*A*B', '**AB'],
    5: ['ABC**', 'AB*C*', 'AB**C', 'A*BC*', 'A*B*C', 'A**BC', '*ABC*', '*AB*C', '*A*BC', '**ABC']
}
export const aArray = {
    digital: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
    letter: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]
}