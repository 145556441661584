export const tldSelectArray = [
  "crypto",
  "nft",
  "x",
  "blockchain",
  "dao",
  "wallet",
  "888",
  "bitcoin",
  "zil",
];

export const PUBLIC_SEARCH_API =
  "https://unstoppabledomains.com/api/domain/search?q=";
export const PARTNER_SEARCH_API =
  "https://unstoppabledomains.com/api/v2/resellers/kuenwenhua/domains?search=";
export const PARTNER_SEARCH_TOKEN = "zaz5etps14cac62y5ag9t7ny0dbaoc4g";

export const API_SERACH_TEST =
  "https://api.ud-sandbox.com/api/v2/resellers/ud999club/domains";
export const BUY_LINK =
  "https://unstoppabledomains.com/search?ref=udclub03&searchTerm=";
export const REFER_LINK = "https://unstoppabledomains.com/?ref=chonglangplus";
export const API_KEY_TEST = "rlkhe5ta3oj4dn2tocs2ukae96rcxow0";
export const API_KEY = "rlkhe5ta3oj4dn2tocs2ukae96rcxow0";
