import React from "react";
import "./style.css";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import { checkBatchDomains } from "../utils";

export const SearchWindow = ({ value, setValue, setPageState, setDomains }) => {
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const handleSearch = (tld) => {
    var consultData = value.split("\n");
    consultData = consultData.filter((word) => word.length > 2);
    console.log("Consulting", consultData);
    checkBatchDomains(consultData, setDomains);
    setPageState(1);
  };
  const handleClear = () => {
    setValue("");
  };

  return (
    <div className="search-window">
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "100%" },
        }}
        noValidate
        autoComplete="off"
      >
        <div className="button-wrapper">
          <Button
            style={{ width: 100 }}
            variant="outlined"
            onClick={() => {
              handleClear();
            }}
          >
            Clear
          </Button>
          <Button
            style={{ width: 100 }}
            variant="contained"
            onClick={() => {
              handleSearch();
            }}
          >
            SEARCH
          </Button>
        </div>
        <TextField
          fullWidth
          id="outlined-multiline-static"
          multiline
          rows={25}
          value={value}
          placeholder="Enter domins, seperated by newline"
          onChange={handleChange}
        />
      </Box>
    </div>
  );
};
