import React from "react";
import { FoldCard } from "./FoldCard";
import { menuItems } from "../../../menu";

import "./QuickSelection.css";


export const QuickSelection = ({ data }) => {
    const setSelect = (select) => {
        data.setState({
            select
        })
    }
    const setDomains = (domains) => {
        data.setState({
            domains
        })
    }
    const setLoading = (loading) => {
        data.setState({
            loading
        })
    }
    return (
        <div className="qselection-wrapper">
            <h>
                Quick selection
            </h>
            {menuItems.map((menu, index) => {
                const depthLevel = 0;
                return (
                    <FoldCard
                        items={menu}
                        key={index}
                        depthLevel={depthLevel}
                        setSelect={setSelect}
                        select={data.state.select}
                        setArr={setDomains}
                        setLoading={setLoading}
                    />
                );
            })}
        </div>
    )
}