import React, { useState, useContext } from "react";
import { PageContext } from "../../../context";
import { BsChevronDown } from "react-icons/bs"
import { quickSearch } from "../../../utils";

import "./QuickSelection.css"

export const FoldCard = ({ items, depthLevel, setSelect, select, setArr, setLoading }) => {
    const [page, setPage] = useContext(PageContext);
    const [expand, setExpand] = useState(false);
    const buttonClass = (depthLevel === 0) ? "topButton " : "topButton ";
    const topClass = (depthLevel === 0) ? "single-card-wrapper-top" : "";
    const buttonActiveClass = (items.title === select) ? "button-active" : "";

    const handleSelect = (element) => {
        if (!items.submenu) {
            setArr([]);
            setPage(0);
            const res = element.pattern ? element.pattern : element.title;
            setSelect(res);
            console.log(res);
            quickSearch(res, setArr, setLoading);
        }
        setExpand(!expand);
    }
    return (
        <div className={"single-card-wrapper " + topClass}>
            <button className={buttonClass + buttonActiveClass} onClick={() => { handleSelect(items) }}>
                {items.title}
                {items.submenu && <BsChevronDown />}
                {!items.submenu && <div></div>}
            </button>
            {expand &&
                <div className={"right-shift"}>
                    {(items.submenu) &&
                        items.submenu.map((menu, index) => {
                            return (
                                <FoldCard
                                    items={menu}
                                    key={index}
                                    depthLevel={depthLevel + 1}
                                    setSelect={setSelect}
                                    select={select}
                                    setArr={setArr}
                                    setLoading={setLoading}
                                />
                            );
                        })
                    }
                </div>
            }
        </div>
    )
}