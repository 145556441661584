export const quickSelectionMap = {
    "ALL": Array.from({ length: 1000 }, (item, index) => index.toString()),
    "ALL3": Array.from({ length: 1000 }, (item, index) => index.toLocaleString('en-US', {
        minimumIntegerDigits: 3,
        useGrouping: false
    })),
    "ALL4": Array.from({ length: 10000 }, (item, index) => index.toLocaleString('en-US', {
        minimumIntegerDigits: 4,
        useGrouping: false
    })),
}

export const digital = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9',];
export const letters = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];